.coinpricegroup{
  display: block;
  width: 200px;
  height: 110px;
  border: 1px solid grey;
  border-radius: 5px;
  margin: 30px;
}

.headings{
  margin-top: 2vh;
  margin-bottom: 1vh;
}

.headings .control{
  margin-top: 12px;
  text-align: center;
}

.headings .search{
  margin-top: 12px;
  text-align: center;
}

.headings .control button{
  margin-left: 10px;
  border: 1px solid black;
  background-color: inherit;
}

.headings .control input{
  width: 130px;
}

.footer{
  display: relative;
  bottom: 0;
  margin-left: 10px;
}

.headings .control button:hover{
  background-color: #f1f1f1;
}

.headings .control label{
  margin-left: 10px;
  color: red;
}

.coinpricegroup h1{
  font-size: 24px;
}

.coinpricegroup h2{
  font-size: 18px;
}

.coinpricegroup *{
  text-align: left;
}

.coinpricegroup .content{
  padding: 20px;
}


.fullRow{
  display: block;
  width: 95vw;
}